import { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { loginUser } from "../requests/requests";
import { useSignIn } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { useSetUser } from "../hooks/UserAuthorization";

const Login = () => {
  const setUser = useSetUser();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const queryClient = useQueryClient();
  const signin = useSignIn();
  let navigate = useNavigate();

  const {
    mutate: authUser,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useMutation(loginUser, {
    onSuccess: (res) => {
      setUser(res.data);
      signin({
        token: res.data.token,
        expiresIn: 3600,
        tokenType: "Bearer",
        authState: { email: email },
      });
      queryClient.invalidateQueries("students");

      res.data.role === "admin"
        ? navigate("/students")
        : navigate(`/${res.data.role}/${res.data.id}`);
    },
    onError: (error) => {
      console.error(error.response.data.error);
    },
  });

  const handleLogin = (e) => {
    e.preventDefault();

    authUser({
      email: email,
      password: password,
    });
  };

  return (
    <div className="container-fluid gap-100">
      <div
        className="row"
        style={{
          backgroundImage: "url('loginpagebg.jpg')",
          backgroundPosition: "center left",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="cold-md-12">
          <form className="my-5 login-form" onSubmit={handleLogin}>
            {console.log(error)}
            {isError && (
              <div className="alert alert-dark my-4" role="alert">
                {error.response.data.error}
              </div>
            )}

            <div className="logo-wrapper">
              <img src="logo.png" alt="" className="logo" />
              <h1 className="my-3 subheading">Recruitment Portal</h1>
              <h2 className="small-heading">
                Where certified industry professionals and companies seeking
                their next qualified hire connect!
              </h2>
            </div>
            <div className="my-4">
              <div className="mb-3">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  required={true}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Password</label>
                <input
                  type="password"
                  className="form-control"
                  required={true}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <button type="submit" className="button-primary">
                Login
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="row">
        <div className="text-center p-0">
          <img src="logos.jpg" alt="" className="img-fluid w-100" />
        </div>
      </div>

      <div className="row bg-black p-4">
        <div class="p-4 rounded h-10 col-md-4 mx-auto p-2 text-white bg-black-200 my-5">
          <h2>Certified Pro</h2>

          <p>
            To become an officially certified pro and join our portal, register
            for one of our courses today.
          </p>

          <div className="mb-4">
            <a
              href="https://www.youtube.com/watch?v=YOUU7WABFTQ"
              target="_blank"
            >
              Learn more
            </a>
          </div>

          <a
            href="https://www.en.be-licensed.com/"
            target="_blank"
            className="btn btn-primary"
          >
            View our courses!
          </a>

          <div className="my-4">
            <p className="text-sm">
              * If you hold one or more active certifications for Media & IT,
              contact us for a free profile.
            </p>
          </div>
        </div>

        <div class="p-4 text-white rounded h-10 col-md-4 p-2 mx-auto bg-black-200 my-5">
          <h2>Companies</h2>

          <p>
            Is your company searching for your next qualified recruit? Contact
            us for an account and enjoy free access to the portal.
          </p>

          <div className="mb-4">
            <a
              href=" https://www.youtube.com/watch?v=OI6Iov-F-ak"
              target="_blank"
            >
              Learn more
            </a>
          </div>

          <a
            href="https://youtu.be/YOUU7WABFTQ"
            target="_blank"
            className="btn btn-primary"
          >
            Get in touch!
          </a>
        </div>
      </div>

      {/* <div className="row">
        <div className="col-md-12 d-flex justify-content-center p-4">
          <div>
            <h2 className="text-center mb-4">Våra partners</h2>

            <div>
              <img src="partners.png" alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Login;
